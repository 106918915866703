import {useTranslation} from "react-i18next";
import {z} from 'zod';
import {useForm} from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import {Text} from "../../components/text";
import {FC} from "react";
import {Card} from "../../components/card";
import {Input} from "../../components/input";
import {Checkbox} from "../../components/checkbox";
import {Button} from "../../components/button";
import {cn} from "../../utils";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {config} from "../../config";
import {Voice} from "../../@types";
import {toast} from "react-hot-toast";
import {RECORD_ITEMS_QUERY_OPTIONS, RECORD_QUERY_OPTIONS} from "../../constants/query";

const schema = z.object({
  email: z.string().email(),
  name: z.string().regex(/[a-z_-]+/i),
  consent: z.literal<boolean>(true),
  privacy: z.boolean(),
});

type FormValues = z.infer<typeof schema>;

export const ParticipationForm: FC<{ voice: Voice, onDone: () => void }> = ({ voice, onDone }) => {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { handleSubmit, register, formState } = useForm<FormValues>({
    // mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      name: '',
      consent: true,
      privacy: false,
    }
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (body: FormData) => fetch(`${config.backend}/api/voices/${voice.uuid}`, {
      method: 'POST',
      body,
      headers: {
        'ACCEPT': 'application/json'
      }
    }),
    onSuccess: async (r, v) => {
      if (r.ok) {
        const vEmail = v.get('email');
        const vName = v.get('name');

        try {
          await queryClient.invalidateQueries({ queryKey: RECORD_ITEMS_QUERY_OPTIONS.queryKey });
        } catch (e) {}
        queryClient.setQueryData<Voice>(RECORD_QUERY_OPTIONS(voice.uuid).queryKey, (d) => (
          d && ({
            ...d,
            name: typeof vName === 'string' ? vName : d.name,
            email: typeof vEmail === 'string' ? vEmail : d.email,
          })
        ));
        onDone();
      } else {
        try {
          const d = await r.json() as { error: string } | {
            message: string,
            errors: Partial<Record<string, string[]>>
          };
          toast.error(('error' in d && d.error) || ('message' in d && 'errors' in d && d.message) || t('Something went wrong'));
        } catch (e) {
          toast.error(t('Something went wrong'));
        }
      }
    },
    onError: () => {
      toast.error(t('Something went wrong'));
    },
  });
  const onSubmit = ({email, name, consent, privacy}: FormValues) => {
    const formData = new FormData();
    formData.set('email', email);
    formData.set('name', name);
    formData.set('subscribe', privacy ? '1' : '0');
    formData.set('consent', privacy ? '1' : '0');
    formData.set('privacy', consent ? '1' : '0');
    formData.set('language', i18n.language.toUpperCase());
    formData.set('_method', 'put');
    mutate(formData);
  };

  return (
    <div className="max-w-screen-xl w-screen px-5 mx-auto py-6 print:p-0">
      <div className="flex flex-col items-center">
        <div className="text-center md:mb-10 mb-7">
          <Text as="div" size="h2" shadow="dark" uppercase className={cn('md:mb-8 mb-5')}>{t('MAGNIFICO MAESTRA!')}</Text>
          <Text as="div" size="h4" shadow="lite" uppercase className="mb-2">{t('You have sung for')}</Text>
          <Text as="div" size="h3" shadow="lite" color="pink" uppercase>{voice.duration.toFixed(2).padStart(5, '0')} SEC!</Text>
        </div>
        <Card size="l" className="md:p-10 p-5 w-full max-w-[520px]">
          {(formState.errors.name || formState.errors.email) && (
            <div className="bg-pink md:p-5 p-3 rounded-[6px] md:mb-6 mb-5">
              <Text as="div" size="p" weight="normal" font="myriad">
                <div>{t('Please fill in the required fields:')}</div>
                <ul className="list-disc pl-4">
                  {formState.errors.name && <li>{t('Your nickname')}</li>}
                  {formState.errors.email && <li>{t('Your email')}</li>}
                </ul>
              </Text>
            </div>
          )}
          <form onSubmit={isPending ? e => e.preventDefault() : handleSubmit(onSubmit)} className="md:space-y-6 space-y-5">
            <Input disabled={isPending} required label={t('Your nickname')} placeholder={t('Enter your nickname')} {...register('name')} />
            <Input disabled={isPending} required type="email" label={t('Your email')} placeholder={t('Enter your email address')} {...register('email')} />
            <div>
              <Checkbox disabled={isPending} className="flex-none" {...register('privacy')} label={t('1st & 2nd harmonised party consent')}/>
            </div>
            <div className="flex justify-center">
              <Button disabled={isPending} type="submit" className="uppercase max-md:w-full">{t('Submit')}</Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};
