import {forwardRef} from 'react';
import type {InputHTMLAttributes, ReactNode} from "react";
import {cn} from "../utils";

export const Input = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & { label: ReactNode }>(({ className, label, ...rest }, ref) => (
  <label className="flex flex-col gap-3">
    <span className="text-white font-myriad text-lg/none font-semibold">{label}</span>
    <input
      ref={ref}
      {...rest}
      className={cn('px-5 py-3 rounded-[10px] font-myriad text-lg text-dark-purple placeholder:text-dark-purple', className)}
    />
  </label>
));
