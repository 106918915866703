import {cn} from "../../utils";
import styles from './styles.module.css';
import type {FC, PropsWithChildren} from "react";

const WEIGHTS = {
  bolder: 'font-black',
  bold: 'font-bold',
  semi: 'font-semibold',
  normal: 'font-normal',
}

export const Text: FC<PropsWithChildren<{
  size?: `h${1 | 2 | 3 | 4 | 5}` | 'p',
  weight?: 'bolder' | 'bold' | 'semi' | 'normal',
  uppercase?: boolean,
  center?: boolean,
  className?: string,
  color?: 'pink' | 'yellow' | 'blue',
  font?: 'myriad' | 'intro-black' | 'alwyn'
  shadow?: 'dark' | 'lite',
  as?: 'span' | 'div' | 'p',
  html?: string,
}>> = ({shadow, html, color, font, uppercase, size, center, children, className, as: C = 'span', weight}) => (
  <C
    dangerouslySetInnerHTML={html ? { __html: html } : undefined}
    className={cn(className,
      styles.text,
      font === 'myriad' && styles.text_font_myriad,
      font === 'alwyn' && styles.text_font_alwyn,
      center && 'text-center',
      uppercase && 'uppercase',
      WEIGHTS[weight ?? 'bolder'],
      !color && 'text-white',
      color === 'pink' && 'text-pink',
      color === 'yellow' && 'text-[#ffb93e]',
      color === 'blue' && 'text-[#271D67]',
      size && styles[`text_size_${size}`],
      shadow && styles[`text_shadow_${shadow}`],
    )}
  >
    {children}
  </C>
);
