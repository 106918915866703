import {ReactComponent as LoadingIcon} from "../../assets/icons/loading.svg";
import {ParticipationRecord} from "./record";
import {ParticipationForm} from "./form";
import {useCallback, useState} from "react";
import {useVoices} from "../../hooks/useVoices";
import {useRecord} from "../../hooks/useRecord";
import {useLSData} from "../../hooks/useLSData";
import {RegulationModal} from "../../components/regulation-modal";

export const ParticipationPage = () => {
  const [{uuid}] = useLSData();
  const [isModalVisible, setModalVisibility] = useState(true);
  const [data, setData] = useState<{ uuid: string | null, duration: number } | null>(null);
  const { voice, voices, isLoading } = useVoices(data?.uuid);
  const { data: prevVoice, isLoading: isPrevLoading } = useRecord(uuid);
  const onDone = useCallback(() => {
    if (voice && voices) {
      window.location.assign(`/result?uuid=${uuid}`);
    }
  }, [uuid, voice, voices])

  return (
    <div className="max-w-screen-xl w-screen px-5 mx-auto py-6 print:p-0">
      {isModalVisible && <RegulationModal onClose={() => setModalVisibility(false)} />}
      {isLoading || isPrevLoading ? (
        <div className="min-h-full flex items-center justify-center">
          <LoadingIcon className="animate-spin"/>
        </div>
      ) : (
        <>
          {voice?.status === 'done' ? (
            <ParticipationForm voice={voice} onDone={onDone} />
          ) : (
            <ParticipationRecord onData={setData} duration={data?.duration ?? prevVoice?.duration} />
          )}
        </>
      )}
    </div>
  );
};
