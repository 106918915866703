import {Text} from "./text";
import {useTranslation} from "react-i18next";
import {Button} from "./button";
import {ChangeEvent, FC, useCallback, useRef, useState} from "react";
import {Checkbox} from "./checkbox";
import {Link} from "react-router-dom";
import {useLinkFormatter} from "../hooks/useLinkFormatter";

export const RegulationModal: FC<{onClose: () => void}> = ({onClose}) => {
  const { t, i18n } = useTranslation();
  const formatLink = useLinkFormatter();
  const [checked, setChecked] = useState(false);
  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    },
    [setChecked]
  );

  return (
    <div className="fixed z-20 flex justify-center items-center p-5 inset-0 backdrop-blur-lg bg-[#7d69ac99]">
      <div className="max-w-[440px] flex-1 p-10 bg-white rounded-[20px] space-y-8">
        <Text as="div" size="h3" color="blue" className="text-center">{t('REGULATIONS')}</Text>
        <Text as="div" size="p" color="blue" font="myriad" weight="normal">{t('reguldesc')}</Text>
        <div className="mb-3">
          <Checkbox required className="flex-none" textClassName="" onChange={onChangeHandler} label={
            <>
              {t('Bij deze ga ik akkoord met de')}{' '}
             <Link to={formatLink('/terms')} className="underline">
              {t('actievoorwaarden')}
              </Link>
              <span> & </span>
              <a href={`https://privacy.mondelezinternational.com/eu/${i18n.language}-${i18n.language.toUpperCase()}/privacy-notice/`} target="_blank"
                className="underline">
                {t('privacy policy')}
              </a>
            </>
          }/>
        </div>

        <Button onClick={onClose} disabled={!checked} className="w-full">{t('regulbutton')}</Button>
      </div>
    </div>
  );
};
