import {useTranslation} from "react-i18next";

export const TermsPage = () => {
  const {i18n} = useTranslation();
  return (
    <div className="text-white max-w-[1400px] p-10 mx-auto text-md space-y-4">
      {i18n.language === "fr" ? (
        <>
<p><strong>Règlement de concours</strong></p>
<p><strong>Article 1: l'Organisateur</strong></p>
<p>La société Mondelēz Belgium Services srl dont le siège est établi à
Stationsstraat 100, 2800 Mechelen, Belgique, enregistrée sous le numéro
d'entreprise BE 0821.675.122, organise un concours « Sing LEO – Win a
looong Break » (ci-après le « concours »).</p>
<p><strong>Article 2: Durée du concours</strong></p>
<p>Le concours se déroule du 01/08/2024 (00h01) au 30/11/2024
(23h59).</p>
<p><strong>Article 3: Conditions</strong></p>
<p>Le concours est ouvert à toute personne majeure (à la date de
l'action) résidant en Belgique ou au Luxembourg, à l'exclusion du
personnel de l'Organisateur, ainsi que de toute entité directement ou
indirectement impliquée dans la conception, l'organisation, la
réalisation et/ou la gestion de l'action.</p>
<p>L'Organisateur se réserve le droit d'exclure un participant du
concours et / ou de ne pas attribuer le prix et / ou d'annuler tout ou
partie du concours, sans engager la responsabilité de l'Organisateur, en
cas de violation par un participant du présent Règlement et/ou si
l’Organisateur, à sa seule discrétion, pense que le participant a agi ou
a l’intention d’agir de manière malhonnête ou frauduleuse, ou de
mauvaise foi.</p>
<p>En particulier, mais sans limitation, toute utilisation de robots ou
tout système, appareil ou manoeuvres frauduleux, malhonnêtes ou de
mauvaise foi, ou toute conduite, permettant par exemple et sans
limitation, de jouer automatiquement et/ou d’augmenter les chances de
gagner, est interdit.</p>
<p>L’Organisateur peut déposer une plainte et/ou porter plainte contre
les auteurs de telles conduites.</p>
<p>En cas de sanction ou de plainte, les participants devront prouver
qu’ils ont adopté une conduite qui est conforme au présent
Règlement.</p>
<p>Les participants peuvent être invités à n’importe quel moment à
apporter la preuve de leur identité, résidence, et âge.</p>
<p><strong>Article 4: Prix</strong></p>
<p><strong>4.1: Description des prix</strong></p>
<p>Les prix à gagner pour l'ensemble du concours sont :</p>
<ul>
<li><p><u>Prix Principal :</u> 1 prix attribué pour un "1 mois de
congé", équivalant à un mois de salaire d'une valeur totale de trois
mille cinq-cents euros (3.500,00€) TTC. (déterminé sur base de la
moyenne des salaires belges bruts au 1er janvier 2024).</p></li>
<li><p>18 prix hebdomadaires attribués pour une "1 semaine de congé"
chacun, équivalant à une semaine de salaire d'une valeur totale de
huit-cents cinquante euros (850,00€) TTC. (déterminé sur base de la
moyenne des salaires belges bruts au 1er janvier 2024).</p></li>
<li><p>18 participations au « Belgian Championship LEO Call », décernées
aux mêmes gagnants que ceux des prix pour la « semaine de congé
».</p></li>
</ul>
<p><strong>4.2: Détails des prix</strong></p>
<p>La valeur des prix est déterminée à la date de préparation du présent
règlement et ne peut être contestée. Les prix ne sont pas transférables,
ne peuvent pas être remplacés par un prix différent et il n'y a pas
d'alternative possible en espèces. L'Organisateur a le droit de
remplacer le prix annoncé par un prix ayant une valeur et des
caractéristiques similaires, si un tel remplacement est nécessaire.</p>
<p>Les gagnants sont entièrement responsables de l'utilisation de leur
prix. L'Organisateur ne pourra être tenu responsable de tout défaut ou
problème avec les prix ou de tout incident ou accident pouvant survenir
aux gagnants pendant ou du fait de l'utilisation ou de la possession du
prix.</p>
<p><strong>Article 5: Détermination des gagnants et remise des
prix</strong></p>
<p><strong>5.1: Pour participer au concours, le participant doit prendre
les mesures suivantes :</strong></p>
<ol type="1">
<li><p>Scanner le QR code figurant sur les emballages LEO ou visiter le
site internet <a href="http://www.leo.be">www.leo.be</a>.</p></li>
<li><p>Cocher la case pour consentir au traitement des données
personnelles.</p></li>
<li><p>Enregistrer votre « LEO Call » le plus long en appuyant sur le
bouton START.</p></li>
<li><p>Soumettre votre « LEO Call », ou recommencer si
nécessaire.</p></li>
<li><p>Saisissez votre pseudonyme et votre adresse e-mail, et cochez les
règles de confidentialité.</p></li>
</ol>
<p>Le terme "LEO Call" désigne le fait de chanter "LEEEEEOOOO" le plus
longtemps possible.</p>
<p>La participation est valable pour la semaine où le participant a
soumis sa participation. Si aucun prix n’a été remporté, le participant
peut retenter sa chance les semaines suivantes.</p>
<p>A noter que :</p>
<ul>
<li><p>La participation est limitée à une participation par semaine par
adresse e-mail. Le participant pourra participer à nouveau les semaines
suivantes.</p></li>
<li><p>Les semaines sont comptées du lundi 00:00:00 au dimanche
23:59:59.</p></li>
<li><p>La confirmation de participation sera affichée sur la page web
après soumission du formulaire, aucun e-mail de confirmation de
participation ne sera envoyé.</p></li>
</ul>
<p><strong>5.2: Sélection des gagnants :</strong></p>
<p>Toutes les participations complètes soumises sur la page <a
href="http://www.leo.be">www.leo.be</a> seront qualifiées et soumises à
procédure de sélection d’évaluation.</p>
<p>Dans cette phase, les participations sont évaluées selon les aspects
suivants :</p>
<blockquote>
<p>- Tous les enregistrements audio des « appels LEO » sont examinés par
les plateformes d'IA existantes (DeepGram et OpenAI) afin de calculer la
durée des enregistrements vocaux et de vérifier que le participant dit «
LEEEOOO » conformément aux conditions d'utilisation de la plateforme
d'IA en question.</p>
</blockquote>
<ul>
<li><p>À la clôture de chaque semaine, un jury évaluera l'ensemble des
participations reçues jusqu'au dimanche à 23h59m59s. Le participant
ayant réalisé le "LEO Call" le plus long sera désigné comme gagnant de
la semaine. Ce vainqueur sera annoncé avant le mercredi de la semaine
suivante et informé par e-mail le jeudi de cette même semaine.</p></li>
<li><p>Cet e-mail demandera le numéro de téléphone du gagnant. Le
gagnant sera appelé à un moment convenu d'un commun accord et ses
performances seront vérifiées par téléphone afin d'exclure les fausses
inscriptions.</p></li>
<li><p>Après le contrôle des performances par téléphone, le gagnant de
la semaine sera invité à fournir ses coordonnées bancaires en réponse à
l'e-mail l'informant de son prix (envoyé le jeudi de la semaine suivant
sa participation). Cet e-mail sera envoyé par BUTIK.</p>
<ul>
<li><p>Mondelez collabore avec l'agence créative BUTIK située à
Schaarbeeklei 647, 1800 Vilvoorde, pour la gestion des prix. BUTIK
informera les gagnants par courrier électronique.</p></li>
</ul></li>
<li><p>Les vainqueurs hebdomadaires seront également sélectionnés pour
participer au "Belgian Championship LEO Call", durant lequel ils
pourront tenter leur chance pour remporter le prix principal, « 1 mois
de congé ». Les modalités exactes et les conditions d'inscription à ce
concours final seront annoncées en détail le 30 novembre 2024 et peuvent
être consultées à l'adresse suivante : <a
href="file:///G:\Gedeelde%20drives\_CLIENTS\2024\MON%20-%20LEO\LOOOOONG%20BREAK\WEBSITE\www.leo.be">www.leo.be</a>.</p></li>
<li><p>Les participants ne faisant pas partie des 18 gagnants
hebdomadaires, pourront retenter leur chance lors du « Belgian
Championship LEO Call ». Uniquement les 10 premières personnes qui se
présentent à l’événement (plus d'informations suivront. Consultez notre
site web pour connaître les règles du concours final), qui aura lieu à
19h le 12 décembre 2024 au Moose Bar à Anvers, pourront retenter leur
chance. Ceux-ci participeront à un round de pré-sélection dans lequel
ils devront réaliser le plus long « LEO Call ». Le vainqueur de cette
pré-sélection, participera à la finale, le même soir, aux côtés des 18
gagnants hebdomadaires.</p>
<ul>
<li><p>Le vainqueur de la finale de ce championnat, déterminé parmi les
19 participants ayant réalisé le "LEO Call" le plus long de la soirée,
se verra attribuer le prix principal.</p></li>
</ul></li>
</ul>
<p>À noter que :</p>
<ul>
<li><p>Chaque participant ne peut gagner qu'une seule fois la semaine de
congé.</p></li>
</ul>
<p>Les gagnants recevront un e-mail afin de prendre les dispositions
nécessaires pour la réception du prix gagné.</p>
<p>Les noms des gagnants seront publiés sur le site web de <a
href="https://www.leo.be/"><span>www.leo.be</span></a>.</p>
<p>Ces critères d'évaluation sont pris en compte par les employés de la
société mère de Milka - Mondelez International.</p>
<p><strong>Article 6: Règlement</strong></p>
<p>La participation au concours implique l'acceptation pleine et entière
du présent règlement. Cochez la case pour donner votre consentement au
traitement des données personnelles.</p>
<p>Aucune réponse ne sera donnée aux demandes soumises (par e-mail ou
par téléphone) sur l'interprétation ou l'application des règles
relatives aux conditions générales de la promotion.</p>
<p>Le règlement est disponible sur <a
href="http://www.leo.be">www.leo.be</a> pendant la durée du
concours.</p>
<p>Ce règlement peut également être obtenu jusqu'au 30/11/2024 sur
simple demande écrite adressée à : Mondelez Belgium srl- Consumer
Service, Stationsstraat 100, 2800 Mechelen. Les frais postaux pour cette
demande ne seront pas remboursés.</p>
<p>Pour tous les éléments du concours qui ne sont pas explicitement
mentionnés dans ce règlement, l'Organisateur se réserve le droit de
prendre toutes les mesures nécessaires pour une bonne organisation du
concours. L'Organisateur se réserve le droit de modifier ces règles si
nécessaire.</p>
<p><strong>Article 7: Frais de participation</strong></p>
<p>La participation à la promotion est gratuite, à l'exception des frais
d'accès à Internet ou aux services postaux, qui sont à la charge du
participant lui-même.</p>
<p><strong>Article 8: Limitation de responsabilité</strong></p>
<p>8.1</p>
<p>L'Organisateur ne peut être tenu responsable des problèmes inhérents
à la connexion Internet, à la communication téléphonique ou à tout autre
problème qui ne pourrait être imputé à l'Organisateur et qui se
produirait pendant la durée du concours.</p>
<p>L'Organisateur met tout en oeuvre pour fournir et maintenir aux
participants une infrastructure, des informations et des outils
fonctionnels et vérifiés. Toutefois, l'Organisateur ne peut être tenu
responsable de la défaillance des équipements des participants
(ordinateur, logiciels, outils de connexion à Internet, téléphone,
serveurs...), de la perte de données qui en résulterait et qui ne serait
pas imputable à l'Organisateur et de l'impact de ces défaillances sur
leur participation au concours. Il appartient donc à chaque participant
de prendre toutes les mesures appropriées pour protéger son équipement
et les données stockées sur son équipement (ordinateur et téléphone)
contre les violations. En particulier, la participation au concours
implique la connaissance et l'acceptation des caractéristiques et des
limites de l'Internet, l'absence de protection de certaines données
contre des détournements éventuels ou le piratage par des tiers et le
risque de contamination par des virus éventuels circulant sur
Internet.</p>
<p>L'Organisateur s'engage à utiliser toutes les ressources disponibles
avec ses prestataires de services pour assurer le bon déroulement du
concours. Toutefois, si une défaillance technique devait survenir et
affecter le bon déroulement du concours en raison de circonstances
indépendantes de la volonté de l'Organisateur, ce dernier ne pourrait
être tenu pour responsable par les participants.</p>
<p>8.2</p>
<p>Les gagnants sont entièrement responsables de l'utilisation de leur
prix. L'Organisateur ne peut être tenu responsable de tout défaut ou
problème lié aux prix ou de tout incident ou accident pouvant survenir
aux gagnants pendant ou en raison de l'utilisation ou de la possession
du prix.</p>
<p>8.3</p>
<p>En donnant le consentement requis à l'aide de la case à cocher et en
participant à ce concours, tous les participants indiquent qu'ils
acceptent les présentes conditions générales du concours.</p>
<p>8.4</p>
<p>Si l'une de ces clauses est déclarée illégale, invalide ou
inapplicable, elle sera dissociée et retirée des présentes conditions
d'action. Les autres clauses seront conservées et resteront en
vigueur.</p>
<p>8.5</p>
<p>Ces conditions de promotion prévalent en cas de conflit ou
d'incohérence avec toute autre communication, y compris le matériel
publicitaire ou promotionnel. Toutes les inscriptions et instructions
sont réputées faire partie des conditions de la promotion et, en
s'inscrivant, tous les participants sont censés avoir accepté et être
liés par ces conditions de la promotion. Veuillez en garder une copie
pour votre information.</p>
<p>8.6</p>
<p>Les gagnants recevront leur prix environ 4 semaines après la date
d'annonce des résultats. Les gagnants seront informés par courrier
électronique. Ils devront répondre à cet e-mail dans un délai d'une
semaine pour confirmer le prix et communiquer leurs coordonnées
bancaires. Si les gagnants n'ont pas répondu dans un délai d'une
semaine, le prix sera attribué au gagnant suivant sur la liste
d'attente. Les prix peuvent être reçus comme indiqué par l'Organisateur
par courrier électronique.</p>
<p>Les vainqueurs hebdomadaires seront également sélectionnés pour
participer au "Belgian Championship LEO Call", durant lequel ils
pourront tenter leur chance pour remporter le prix principal, « 1 mois
de congé ».</p>
<p>Cette sélection pour l’événement est nominative (au nom du gagnant
hebdomadaire) et ne peut être transmise à un tiers. Si les gagnants
hebdomadaires ne souhaitent pas se rendre au Belgian Championship LEO
Call, ils renoncent alors à la possibilité de remporter le mois de congé
et ne pourront exiger de compensation. Le fait de ne pas se rendre à
l’événement, les disqualifiera du concours.</p>
<p>Dans le cas où l'Organisateur ne peut atteindre le gagnant par e-mail
(conformément aux coordonnées indiquées sur le formulaire d'inscription)
après 1 tentative (message envoyé à l’adresse mail communiquée par le
participant), si le gagnant n'a pas réclamé son prix dans la [1] semaine
après la date de l'e-mail l'informant de ses gains, il sera considéré
comme ayant purement et simplement perdu son prix. Le prix n'est pas
attribué et ne peut être réclamé plus tard. Dans ce cas, le prix reste
la propriété de l'Organisateur et peut être attribué à un autre gagnant
ou utilisé dans une promotion ultérieure.</p>
<p>8.7</p>
<p>Ce concours pourrait éventuellement être affectée par les efforts
imposés pour freiner la propagation de la COVID19. L’Organisateur se
réserve donc le droit de</p>
<ul>
<li><p>reporter/annuler tout ou partie du concours</p></li>
<li><p>remplacer ou annuler un certain prix si l’Organisateur le juge
nécessaire en raison de restrictions imposées par les autorités locales
ou de toute exigence liée à la COVID19.</p></li>
</ul>
<p>Les gagnants peuvent être tenus de passer un test COVID 19 si cela
s'avère nécessaire. Les gagnants doivent se conformer à toutes les
exigences liées à la COVID19.</p>
<p><strong>8.8</strong></p>
<p>Nos partenaires utilisent des plateformes d'IA existantes (DeepGram
et OpenAI) pour calculer le temps de durée des enregistrements vocaux et
vérifier si le Participant dit LEEEOOO sur la base des conditions
d'utilisation de cette plateforme d'IA. L'Organisateur et ses
partenaires ne sont pas affiliés à ces plateformes d'IA et aux services
des plateformes d'IA utilisées pour ce concours. Les données seront
envoyées aux serveurs de la plateforme d'IA où elles seront traitées aux
fins décrites ci-dessus, après quoi elles seront supprimées.
L'Organisateur n'est pas responsable du traitement et de l'utilisation
des contributions ou des données partagées par le participant par
l'intermédiaire de la plateforme d'IA. En participant à ce concours,
vous acceptez de dégager l'Organisateur de toute responsabilité
concernant la collecte, le stockage et l'utilisation des données par la
plateforme d'IA et vous comprenez et acceptez que toutes les données
saisies par le participant peuvent être utilisées par la plateforme d'IA
comme décrit dans le présent document.</p>
<p><strong>Article 9 : Prendre contact</strong></p>
<p>Les correspondances non conformes (incomplètes, illisibles,
incorrectes, non affranchies, retardées ou non envoyées) ne seront pas
prises en compte.</p>
<p><strong>Article 10 : Déchéance</strong></p>
<p>Les participants doivent permettre toutes les vérifications
concernant leur identité, leur âge, leur adresse et leur numéro de
téléphone dans le cadre de la promotion. Toute participation non
conforme, incomplète ou avec des coordonnées incorrectes sera considérée
comme nulle.</p>
<p>L'utilisation de robots ou de toute autre méthode similaire pour
jouer à l'Action de manière mécanique ou autre est interdite, la
violation de cette règle entraînera l'élimination définitive du
participant et/ou de l'utilisateur. L'Organisateur pourra annuler tout
ou partie de la promotion s'il apparaît que des fraudes sont intervenues
sous quelque forme que ce soit, par voie informatique ou par le fait de
la participation sous l'identité d'un tiers et/ou de la détermination
des gagnants. Dans ce cas, l'Organisateur se réserve le droit de ne pas
attribuer de prix aux fraudeurs et/ou de poursuivre les auteurs de ces
fraudes devant les tribunaux compétents.</p>
<p><strong>Article 11: Force majeure</strong></p>
<p>L'Organisateur n'est pas responsable en cas de force majeure ou
d'événements sur lesquels il n'a aucun contrôle ou si, en raison des
circonstances, il est obligé d'annuler le présent concours, de
raccourcir, prolonger, reporter, suspendre ou modifier les conditions.
Dans tous les cas, l'Organisateur se réserve le droit de prolonger la
période de participation.</p>
<p><strong>Article 12: Exemption de responsabilité</strong></p>
<p>Les gagnants sont entièrement responsables de l'utilisation de leur
prix. L'Organisateur ne peut être tenu responsable des défauts ou des
problèmes avec les prix ou de tout incident ou accident qui pourrait
arriver aux gagnants pendant ou en raison de l'utilisation ou la
possession du prix.</p>
<p><strong>Article 13: Droits de propriété intellectuelle et droits
d'auteur</strong></p>
<p>Conformément aux lois relatives aux droits de propriété
intellectuelle et aux droits d'auteur, l'utilisation de tout ou partie
du matériel protégé par le droit d'auteur de ce concours est strictement
interdite, sauf accord préalable et explicite de l'Organisateur.</p>
<p><strong>Article 14 : Droits de propriété intellectuelle et droits
d'auteur</strong></p>
<p>Tous les droits d'auteur des créations des participants deviennent la
propriété de Mondelez.</p>
<p>Conformément aux lois relatives aux droits de propriété
intellectuelle et au droit d'auteur, l'utilisation de tout ou partie du
matériel protégé par le droit d'auteur de ce concours est strictement
interdite, sauf avec le consentement exprès préalable de
l'Organisateur.</p>
<p>En participant à un concours avec apport créatif, le participant
autorise l'Organisateur, sans qu'aucune compensation ne soit due, et
sans aucune limitation dans le temps et l'espace, à :</p>
<p>- d'être publié sur le(s) site(s) web de l'Organisateur (adapté(s) ou
non à une utilisation mobile) et sur d'autres supports numériques (tels
que Facebook, YouTube, ...) ;</p>
<p>Le participant déclare qu'il dispose des droits nécessaires (droits
de propriété intellectuelle et droits de la personnalité) pour permettre
cette utilisation de sa contribution créative et garantit l'Organisateur
contre toute réclamation éventuelle en rapport avec sa contribution
créative. Cela implique donc qu'une contribution créative doit être
originale, doit avoir été créée uniquement par le(s) participant(s) et
ne doit en aucun cas incorporer du matériel de tiers protégé par le
droit d'auteur ou autre (sauf s'il/elle a obtenu toutes les
autorisations nécessaires de la part des détenteurs des droits).</p>
<p>L'Organisateur n'a aucune obligation de publier et d'utiliser une
contribution créative et peut la supprimer à tout moment et pour
n'importe quelle raison.</p>
<p><strong>Article 15: Loi applicable et litiges</strong></p>
<p>Le concours est soumis à la loi belge. Tout litige relatif à
l'interprétation des règles et / ou des cas non prévus au présent
règlement fera l'objet d'un règlement à l'amiable. Dans le cas
contraire, les tribunaux d'Anvers seront exclusivement compétents.</p>
<p><strong>Article 16: Protection des données personnelles</strong></p>
<p>Nous utilisons les données personnelles que nous recueillons à votre
sujet pour vous permettre de participer au Concours et vous contacter si
vous avez gagné un prix. Les informations que nous collectons à votre
sujet ne seront collectées que dans le but d'identifier le(s)
gagnant(s). Ces données ne seront conservées que pendant la durée du
Concours (terme du Concours tel que définie à l'article 2, ainsi que la
période pendant laquelle le (s) gagnant (s) seront désignés et les prix
sont attribués). Consultez notre <a
href="file:///C:/Users/UCU2021/AppData/Roaming/Microsoft/Word/Règlement%20de%20concours%20Article%201:%20l%27Organisateur">Notice
Vie Privée</a> pour plus d'informations sur la façon dont nous traitons
les données personnelles.</p>
<ul>
<li><p>Top of Form</p></li>
</ul>
        </>
      ) : (
        <>
<p><strong>Wedstrijdreglement</strong></p>
<p><strong>Artikel 1: De organisator</strong></p>
<p>Mondelēz Belgium Services bv, met maatschappelijke zetel te
Stationsstraat 100, 2800 Mechelen, België, geregistreerd onder
ondernemingsnummer BE 0821.675.122, (Hierna de “Organisator”)
organiseert een wedstrijd « Sing LEO – Win a looong Break » (hierna de
"wedstrijd").</p>
<p><strong>Artikel 2: Duur van de wedstrijd</strong></p>
<p>De wedstrijd loopt van 01/08/2024 (00u01) tot 30/11/2024 (23u59).</p>
<p><strong>Artikel 3: Voorwaarden</strong></p>
<p>De wedstrijd staat open voor elke meerderjarige persoon (op de datum
van de actie) woonachtig in België of in Luxemburg, met uitzondering van
het personeel van de Organisator, evenals elke entiteit die direct of
indirect betrokken is bij het ontwerp, de organisatie, de uitvoering
en/of het beheer van de actie.</p>
<p>De Organisator behoudt zich het recht om een deelnemer uit te sluiten
van de wedstrijd en/of de prijs niet toe te kennen en/of de wedstrijd
geheel of gedeeltelijk te annuleren, zonder dat de Organisator hiervoor
aansprakelijk kan worden gesteld, in geval van overtreding van dit
reglement door een deelnemer en/of indien de Organisator, naar eigen
goeddunken, van mening is dat de deelnemer oneerlijk of frauduleus heeft
gehandeld of van plan is te handelen, of te kwader trouw.</p>
<p>In het bijzonder, maar zonder beperking, is elk gebruik van robots of
elk frauduleus, oneerlijk of te kwader trouw systeem, apparaat of
manoeuvre, of elk gedrag, bijvoorbeeld en zonder beperking, om
automatisch te spelen en/of de winkansen te vergroten, verboden. De
Organisator kan een klacht indienen en/of gerechtelijke stappen
ondernemen tegen de daders van dergelijk gedrag.</p>
<p>In het geval van een sanctie of klacht moeten deelnemers bewijzen dat
zij zich hebben gedragen in overeenstemming met deze Regels.</p>
<p>Deelnemers kunnen op elk moment worden gevraagd om hun identiteit,
woonplaats en leeftijd aan te tonen.</p>
<p><strong>Artikel 4: Prijzen</strong></p>
<p><strong>4.1: Beschrijving van de prijzen</strong></p>
<p>De te winnen prijzen voor de volledige wedstrijd zijn :</p>
<ul>
<li><p><u>Hoofdprijs</u> : 1 prijs voor “1 maand vakantie”, gelijk aan
een maandsalaris met een totale waarde van drie duizend vijf honderd
euro (€3.500,00) inclusief btw. (bepaald op basis van de gemiddelde
Belgische bruto salarissen op 1 januari 2024).</p></li>
<li><p>18 wekelijkse prijzen voor “1 week vakantie” elk, gelijk aan een
weeksalaris met een totale waarde van acht honderd vijftig euro
(€850,00) inclusief btw. (bepaald op basis van de gemiddelde Belgische
bruto salarissen op 1 januari 2024).</p></li>
<li><p>18 inschrijvingen voor het “Belgisch Kampioenschap LEO Call”,
toegekend aan dezelfde winnaars als de “1 week
vakantie”-prijzen.</p></li>
</ul>
<p><strong>4.2: Details van de prijzen</strong></p>
<p>De waarde van de prijzen wordt bepaald op de datum waarop dit
reglement wordt opgesteld en kan niet worden betwist. Prijzen zijn niet
overdraagbaar, kunnen niet worden vervangen door een andere prijs en er
is geen alternatief in contanten. De Organisator heeft het recht om de
geadverteerde prijs te vervangen door een prijs van vergelijkbare waarde
en kenmerken, als een dergelijke vervanging noodzakelijk is.</p>
<p>De winnaars zijn volledig verantwoordelijk voor het gebruik van hun
prijs. De Organisator kan niet aansprakelijk worden gesteld voor
defecten of problemen met de prijzen of voor incidenten of ongevallen
die de winnaars kunnen overkomen tijdens of als gevolg van het gebruik
of het bezit van de prijs.</p>
<p><strong>Artikel 5: Vaststelling van de winnaars en toekenning van de
prijzen</strong></p>
<p><strong>5.1: Om deel te nemen aan de wedstrijd moeten de deelnemers
de volgende stappen ondernemen:</strong></p>
<ol type="1">
<li><p>Scan de QR-code op de LEO-verpakking of bezoek de website <a
href="http://www.leo.be">www.leo.be</a></p></li>
<li><p>Vink het kadertje aan om toestemming te geven om persoonsgegevens
te verwerken</p></li>
<li><p>Neem je langste “LEO Call” op door op de START-knop te
drukken.</p></li>
<li><p>Verzend je LEO Call, of begin opnieuw indien nodig.</p></li>
<li><p>Voer je pseudoniem en je e-mailadres in en vink de
vertrouwelijkheidsregels aan.</p></li>
</ol>
<p>De term 'LEO Call' verwijst naar het zo lang mogelijk zingen van
'LEEEEEOOOO'.</p>
<p>De inzending is geldig voor de week waarin de deelnemer zijn/haar
inzending heeft gedaan. Als er geen prijs is gewonnen, mag de deelnemer
het in de daaropvolgende weken opnieuw proberen.</p>
<p>Let op:</p>
<ul>
<li><p>Deelname is beperkt tot één inzending per week per e-mailadres.
De deelnemer mag in de daaropvolgende weken opnieuw meedoen.</p></li>
<li><p>Weken worden geteld van maandag 00:00:00 tot zondag
23:59:59.</p></li>
<li><p>Bevestiging van deelname wordt weergegeven op de webpagina zodra
het formulier is verzonden. Er wordt geen e-mail ter bevestiging van
deelname verzonden.</p></li>
</ul>
<p><strong>5.2: Selectie van winnaars :</strong></p>
<p>Alle volledige inzendingen die zijn ingediend op de pagina <a
href="http://www.leo.be">www.leo.be</a> komen in aanmerking voor een
selectieprocedure.</p>
<p>In deze fase worden de inzendingen beoordeeld op volgende
aspecten</p>
<ul>
<li><p>Alle “LEO Call” geluidsopnames worden door de bestaande
AI-platformen (DeepGram en OpenAI) gescreend om de duurtijd van de
spraakopnames te berekenen en om na te gaan of de Deelnemer LEEEOOO zegt
op basis van de gebruiksvoorwaarden van een dergelijk
AI-platform</p></li>
<li><p>Aan het einde van elke week beoordeelt een jury alle inzendingen
die voor zondag 23.59 uur zijn ontvangen. De deelnemer met de langste
“LEO Call” wordt gekozen als winnaar van de week. Deze winnaar wordt
uiterlijk woensdag van de volgende week bekendgemaakt en donderdag van
dezelfde week per e-mail op de hoogte gebracht.</p></li>
<li><p>In deze mail wordt gevraagd naar het telefoon nummer van de
winnaar. Deze wordt opgebeld op een tijdstip in onderling overleg en
zijn of haar prestatie wordt telefonisch gecheckt teneinde vervalste
deelnames te kunnen uitsluiten.</p></li>
<li><p>De winnaar van de week wordt na de telefonische prestatie check
gevraagd zijn/haar bankgegevens te verstrekken in antwoord op de e-mail
waarin hij/zij op de hoogte wordt gebracht van zijn/haar prijs
(verzonden op de donderdag van de week na deelname). Deze e-mail wordt
verstuurd door BUTIK.</p>
<ul>
<li><p>Mondelez werkt samen met het creatieve agentschap BUTIK gevestigd
te Schaarbeeklei 647, 1800 Vilvoorde, voor de afhandeling van de
prijzen, BUTIK zal de winnaars informeren via email.</p></li>
</ul></li>
<li><p>De wekelijkse winnaars worden ook gekozen om deel te nemen aan
het “Belgisch Kampioenschap LEO Call”, gedurende dewelke ze hun geluk
kunnen beproeven om de hoofdprijs te winnen, “1 maand vakantie”. De
exacte modaliteiten en registratievoorwaarden voor deze finale wedstrijd
zullen in detail worden bekendgemaakt op 30 november 2024 en is te
consulteren op <a href="http://www.leo.be">www.leo.be</a>.</p></li>
<li><p>Deelnemers die niet tot de 18 wekelijkse winnaars behoren, kunnen
hun geluk opnieuw beproeven tijdens de "Belgian Championship LEO Call".
Alleen de eerste 10 deelnemers aanwezig op het evenement (nadere info
volgt. Check onze website voor het reglement van de finale wedstrijd),
dat plaatsvindt om 19u op 12 december 2024 in de Moose Bar in Antwerpen,
kunnen hun geluk opnieuw beproeven. Zij nemen deel aan een
preselectieronde waarin ze de langste "LEO Call" moeten maken. De
winnaar van deze voorselectie neemt op dezelfde avond deel aan de
finale, naast de 18 wekelijkse winnaars.</p></li>
<li><p>De winnaar van de finale van dit kampioenschap, bepaald uit de 19
deelnemers die de langste “LEO Call” van de avond hebben gemaakt, wint
de hoofdprijs.</p></li>
</ul>
<p>Opgelet:</p>
<ul>
<li><p>Elke deelnemer kan de 1-week vakantie maar één keer
winnen.</p></li>
</ul>
<p>Winnaars ontvangen een e-mail om de nodige regelingen te treffen om
de gewonnen prijs in ontvangst te nemen.</p>
<p>De namen van de winnaars worden gepubliceerd op de website <a
href="http://www.leo.be">www.leo.be</a>.</p>
<p>Deze beoordelingscriteria worden in acht genomen door medewerkers van
het moederbedrijf van Milka - Mondelez International.</p>
<p><strong>Artikel 6: Regels</strong></p>
<p>Deelname aan de wedstrijd impliceert volledige acceptatie van dit
reglement. Vink het kadertje aan om toestemming te geven om
persoonsgegevens te verwerken</p>
<p>Er wordt niet gereageerd op vragen (per e-mail of telefonisch) over
de interpretatie of toepassing van de regels met betrekking tot de
algemene voorwaarden van de actie.</p>
<p>De regels zijn beschikbaar op <a
href="http://www.leo.be">www.leo.be</a> tijdens de wedstrijdperiode.</p>
<p>Dit reglement kan ook worden verkregen tot 30/ 11/2024 door een
schriftelijk verzoek te sturen naar : Mondelez Belgium bv
Consumentenservice, Stationsstraat 100, 2800 Mechelen. Verzendkosten
voor dit verzoek worden niet terugbetaald.</p>
<p>Voor alle elementen van de wedstrijd die niet expliciet worden
vermeld in dit reglement, behoudt de Organisator zich het recht om alle
maatregelen te nemen die nodig zijn voor de goede organisatie van de
wedstrijd. De Organisator behoudt zich het recht om dit reglement te
wijzigen indien nodig.</p>
<p><strong>Artikel 7: Deelnamekosten</strong></p>
<p>Deelname aan de actie is gratis, met uitzondering van de kosten voor
toegang tot internet of postdiensten, die ten laste van de deelnemer
komen.</p>
<p><strong>Artikel 8: Beperking van aansprakelijkheid</strong></p>
<p><strong>8.1</strong></p>
<p>De Organisator kan niet aansprakelijk worden gesteld voor problemen
die inherent zijn aan de internetverbinding, telefooncommunicatie of elk
ander probleem dat niet aan de Organisator kan worden toegeschreven en
dat zich tijdens de wedstrijd voordoet.</p>
<p>De Organisator stelt alles in het werk om een functionele en
geverifieerde infrastructuur, informatie en hulpmiddelen voor de
deelnemers aan te bieden en te onderhouden. De Organisator kan echter
niet aansprakelijk worden gesteld voor het uitvallen van de apparatuur
van de deelnemers (computer, software, hulpmiddelen voor
internetverbinding, telefoon, servers, etc.), voor het daaruit
voortvloeiende verlies van gegevens dat niet aan de Organisator kan
worden toegeschreven en voor de gevolgen van deze storingen voor hun
deelname aan de wedstrijd. Het is daarom de verantwoordelijkheid van
elke deelnemer om alle passende maatregelen te nemen om zijn apparatuur
en de gegevens die op zijn apparatuur zijn opgeslagen (computer en
telefoon) te beschermen tegen inbreuken. In het bijzonder impliceert
deelname aan de wedstrijd kennis en acceptatie van de kenmerken en
beperkingen van het internet, het gebrek aan bescherming van bepaalde
gegevens tegen mogelijke verduistering of piraterij door derden en het
risico van besmetting door virussen die op het internet circuleren.</p>
<p>De Organisator verbindt zich ertoe alle beschikbare middelen in te
zetten bij zijn dienstverleners om het goede verloop van de wedstrijd te
garanderen. Als er echter een technische storing optreedt die het goede
verloop van de wedstrijd beïnvloedt door omstandigheden buiten de
controle van de Organisator, kan deze laatste niet aansprakelijk worden
gesteld door de deelnemers.</p>
<p><strong>8.2</strong></p>
<p>De winnaars zijn volledig verantwoordelijk voor het gebruik van hun
prijs. De Organisator kan niet aansprakelijk worden gesteld voor
defecten of problemen in verband met de prijzen of voor incidenten of
ongevallen die de winnaars kunnen overkomen tijdens of als gevolg van
het gebruik of het bezit van de prijs.</p>
<p><strong>8.3</strong></p>
<p>Door de vereiste toestemming te geven via het selectievakje en deel
te nemen aan deze wedstrijd, geven alle deelnemers aan akkoord te gaan
met deze wedstrijdvoorwaarden.</p>
<p><strong>8.4</strong></p>
<p>Als een van deze clausules onwettig, ongeldig of onuitvoerbaar wordt
verklaard, zal deze uit deze algemene voorwaarden worden verwijderd. De
overige bepalingen blijven van kracht.</p>
<p><strong>8.5</strong></p>
<p>Deze actievoorwaarden prevaleren in geval van tegenstrijdigheid of
inconsistentie met enige andere communicatie, inclusief reclame of
promotiemateriaal. Alle inzendingen en instructies worden geacht deel
uit te maken van de actievoorwaarden en door deel te nemen worden alle
deelnemers geacht akkoord te gaan met en gebonden te zijn door deze
actievoorwaarden. Bewaar een kopie ter informatie.</p>
<p><strong>8.6</strong></p>
<p>De winnaars ontvangen hun prijs ongeveer 4 weken na de datum van
bekendmaking van de resultaten. De winnaars zullen worden geïnformeerd
via e-mail. De winnaars zullen binnen 1 week moeten reageren op de mail
om de prijs te bevestigen en hun bankgegevens te communiceren. Indien de
winnaars niet binnen 1 week hebben gereageerd, zal de prijs gaan naar de
volgende winnaar op de wachtlijst. De prijzen kunnen in ontvangst worden
genomen zoals via e-mail aangegeven door de Organisator.</p>
<p>De wekelijkse winnaars worden ook gekozen om deel te nemen aan het
“Belgisch Kampioenschap LEO Call”, gedurende dewelke ze hun geluk kunnen
beproeven om de hoofdprijs te winnen, “1 maand vakantie”. Deze selectie
voor het evenement is nominatief (in naam van de weekwinnaar) en kan
niet worden doorgegeven aan iemand anders. Als de wekelijkse winnaars
niet wensen deel te nemen aan het “Belgisch Kampioenschap LEO Call”,
verliezen ze de kans om de maand vrij te winnen en kunnen ze geen
aanspraak maken op enige compensatie. Als ze het evenement niet
bijwonen, worden ze gediskwalificeerd voor de competitie.</p>
<p>In het geval dat de Organisator de winnaar na 1 poging per e-mail
(volgens de contactgegevens op het inschrijfformulier) niet kan bereiken
(bericht verzonden naar het door de deelnemer opgegeven emailadres), als
de winnaar zijn/haar prijs niet heeft opgeëist binnen 1 week na de datum
van de e-mail waarin hij/zij werd geïnformeerd over zijn/haar winst,
wordt hij/zij geacht zijn/haar prijs volledig te hebben verloren. De
prijs wordt niet toegekend en kan niet op een later tijdstip worden
opgeëist. In dit geval blijft de prijs eigendom van de Organisator en
kan deze worden toegekend aan een andere winnaar of worden gebruikt in
een toekomstige promotie.</p>
<p><strong>8.7</strong></p>
<p>Deze wedstrijd kan mogelijk worden beïnvloed door de inspanningen die
worden opgelegd om de verspreiding van COVID19 tegen te gaan. De
Organisator behoudt zich daarom het recht voor om</p>
<p>- de wedstrijd geheel of gedeeltelijk uit te stellen/annuleren</p>
<p>- een bepaalde prijs te vervangen of te annuleren als de Organisator
dit nodig acht vanwege beperkingen die worden opgelegd door lokale
autoriteiten of enige vereiste met betrekking tot COVID19.</p>
<p>Winnaars kunnen indien nodig verplicht worden een COVID 19-test af te
leggen. Winnaars moeten voldoen aan alle COVID19-vereisten.</p>
<p><strong>8.8</strong></p>
<p>Onze partners gebruiken bestaande AI-platformen (DeepGram en OpenAI)
om de duurtijd van spraakopnames te berekenen en na te gaan of de
Deelnemer LEEEOOO zegt op basis van de gebruiksvoorwaarden van een
dergelijk AI-platform. De Organisator en haar partners zijn niet
verbonden aan deze AI-platformen en de diensten van de AI-platformen die
voor deze wedstrijd worden gebruikt. Inputs worden naar de servers van
het AI-platform gestuurd waar het wordt verwerkt voor de doeleinden
zoals hierboven beschreven waarna het wordt verwijderd. De Organisator
is niet aansprakelijk voor de verwerking en het gebruik van de Input of
gegevens die door de deelnemer via het AI-platform worden gedeeld. Door
deel te nemen aan deze wedstrijd gaat u ermee akkoord de Organisator te
vrijwaren van elke aansprakelijkheid voor het verzamelen, opslaan en
gebruiken van Inputs door een dergelijk AI-platform en begrijp je en ga
je ermee akkoord dat alle gegevens die door de deelnemer worden
ingevoerd, door het AI-platform kunnen worden gebruikt zoals hierin
beschreven.</p>
<p><strong>Artikel 9: Contact opnemen</strong></p>
<p>Correspondentie die niet voldoet (onvolledig, onleesbaar, onjuist,
niet gefrankeerd, vertraagd of niet verzonden) wordt niet in behandeling
genomen.</p>
<p><strong>Artikel 10: Vervalsing, fraude</strong></p>
<p>Deelnemers moeten alle controles met betrekking tot hun identiteit,
leeftijd, adres en telefoonnummer in het kader van de actie toestaan.
Niet-conforme, onvolledige inzendingen of inzendingen met onjuiste
contactgegevens worden als ongeldig beschouwd.</p>
<p>Het gebruik van robots of andere soortgelijke methoden om de Actie te
spelen, al dan niet mechanisch, is verboden. Elke overtreding van deze
regel leidt tot de definitieve uitsluiting van de deelnemer en/of
gebruiker. De Organisator kan de Actie geheel of gedeeltelijk annuleren
als blijkt dat er fraude is gepleegd, in welke vorm dan ook, via de
computer of door deelname onder de identiteit van een derde en/of de
bepaling van de winnaars. In dit geval behoudt de Organisator zich het
recht voor geen prijzen toe te kennen aan fraudeurs en/of de daders van
dergelijke fraude te vervolgen voor de bevoegde rechtbanken.</p>
<p><strong>Artikel 11: Overmacht</strong></p>
<p>De Organisator is niet aansprakelijk in geval van overmacht of
gebeurtenissen waarover hij geen controle heeft of indien hij door
omstandigheden genoodzaakt is deze wedstrijd te annuleren, in te korten,
te verlengen, uit te stellen, op te schorten of de voorwaarden te
wijzigen.</p>
<p>In alle gevallen behoudt de Organisator zich het recht voor om de
deelnameperiode te verlengen.</p>
<p><strong>Artikel 12: Vrijstelling van aansprakelijkheid</strong></p>
<p>De winnaars zijn volledig verantwoordelijk voor het gebruik van hun
prijs. De Organisator kan niet aansprakelijk worden gesteld voor
defecten of problemen met de prijzen of voor incidenten of ongevallen
die de winnaars kunnen overkomen tijdens of als gevolg van het gebruik
of het bezit van de prijs.</p>
<p><strong>Artikel 13: Machtiging om de namen, adressen en ingezonden
herinneringen van de winnaars te gebruiken</strong></p>
<p>Op voorwaarde van het verkrijgen van de uitdrukkelijke toestemming
van de hoofddeelnemer, kan de Organisator de naam, het adres en de
ingezonden foto en/of tekst van de winnaars gebruiken voor
reclamedoeleinden zonder hen recht te geven op een vergoeding of een
ander voordeel over te dragen.</p>
<p><strong>Artikel 14: Intellectuele eigendomsrechten en
auteursrecht</strong></p>
<p>Alle auteursrechten op de creaties van de deelnemers worden eigendom
van Mondelez.</p>
<p>In overeenstemming met de wetten met betrekking tot intellectuele
eigendomsrechten en auteursrechten is het gebruik van alle of een deel
van het materiaal dat wordt beschermd door auteursrechten in deze
wedstrijd strikt verboden, tenzij met de uitdrukkelijke voorafgaande
toestemming van de Organisator.</p>
<p>Door deel te nemen aan een wedstrijd met creatieve inbreng, verleent
de deelnemer aan de Organisator de toestemming om deze creatieve
inbreng, zonder dat enige vergoeding hiervoor verschuldigd is, en zonder
enige beperking in tijd en ruimte:</p>
<blockquote>
<p>• te publiceren op de (al dan niet voor mobiel gebruik aangepaste)
website(s) van Organisator en andere digitale media (zoals Facebook,
YouTube, …);</p>
</blockquote>
<p>De deelnemer verklaart over de nodige rechten (intellectuele
eigendomsrechten en persoonlijkheidsrechten) te beschikken om dit
gebruik van zijn creatieve inbreng toe te laten en vrijwaart de
Organisator tegen alle mogelijke aanspraken in verband met zijn
creatieve inbreng. Dit brengt dus mee dat een creatieve inbreng
oorspronkelijk moet zijn, enkel en alleen door de deelnemer(s) gecreëerd
mag zijn en er op geen enkele wijze auteursrechtelijk of anderszins
beschermd materiaal van derden in verwerkt is (tenzij hij/zij over alle
noodzakelijke toestemmingen van de rechthebbenden beschikt).</p>
<p>De Organisator is in geen geval verplicht om een creatieve inbreng
ook effectief te publiceren en gebruiken en kan op elk ogenblik en om
welke reden dan ook een creatieve inbreng verwijderen.</p>
<p><strong>Artikel 15: Toepasselijk recht en geschillen</strong></p>
<p>De wedstrijd is onderworpen aan het Belgisch recht. Elk geschil met
betrekking tot de interpretatie van het reglement en/of gevallen waarin
dit reglement niet voorziet, worden buitengerechtelijk beslecht. In
andere gevallen zijn uitsluitend de rechtbanken van Antwerpen
bevoegd.</p>
<p><strong>Artikel 16: Bescherming van persoonsgegevens</strong></p>
<p>We gebruiken de persoonsgegevens die we over je verzamelen om je in
staat te stellen deel te nemen aan de Wedstrijd en om contact met je op
te nemen als je een prijs hebt gewonnen. De gegevens die we over je
verzamelen worden alleen verzameld om de winnaar(s) te identificeren.
Deze gegevens worden alleen bewaard voor de duur van de Wedstrijd (het
einde van de Wedstrijd zoals gedefinieerd in artikel 2, evenals de
periode waarin de winnaar(s) worden aangewezen en de prijzen worden
uitgereikt). Zie onze <a
href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=nl-NL&amp;siteId=W2Ei6lfauxxwcYDJV6%2fg1w%3d%3d">Privacyverklaring</a>
voor meer informatie over hoe wij persoonsgegevens verwerken.</p>
        </>
      )}
    </div>
  );
};
