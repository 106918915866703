import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../assets/icons/leo-logo.svg";
import { ReactComponent as ResultMic } from "../assets/images/result-mic.svg";

export type ShareProps = {
	readonly duration: number;
};

export const Share = (props: ShareProps) => {
	const { t } = useTranslation();

	return (
		<div className="rounded-[15px] border-white border-[12px] bg-white app-share-block">
			<div className="bg-reviewBackgroundGradient rounded-[15px] lg:pl-[50px] lg:pr-5 lg:pt-[60px] px-2 pt-5 flex lg:justify-between max-lg:flex-col max-lg:items-center">
				<div className="max-w-[440px] max-lg:flex lg:pb-[60px] font-intro-black pb-5 max-lg:flex-col max-lg:items-center max-lg:text-center max-lg:mx-auto flex-none [text-shadow:3px_3px_0_rgba(0,0,0,.1)]">
					<div className="lg:mb-24 mb-5">
						<Logo className="max-lg:w-16 max-lg:h-auto aspect-[163/90]" />
					</div>
					<div className="text-white lg:text-[35px] lg:leading-10 text-[22px]/none uppercase lg:mb-20 mb-8">
						<div>{t("I made to the leaderboard!")}</div>
						<div>{t("Check out my score!")}</div>
					</div>
					<div className="lg:mb-6 mb-4 uppercase lg:text-[24px] text-[18px] leading-none text-white">
						{t("my score")}
					</div>
					<div className="[text-shadow:4px_4px_0_rgba(0,0,0,.1)] uppercase lg:text-[78px] text-[32px] leading-none text-[#ffb93e]">
						{props.duration.toFixed(2).padStart(5, "0")} sec
					</div>
				</div>
				<ResultMic className="flex-1 lg:self-end h-auto aspect-[601/571] max-w-[220px] lg:max-w-[600px]" />
			</div>
		</div>
	);
};
