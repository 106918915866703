import {useRecord} from "./useRecord";
import {useRecords} from "./useRecords";

export const useVoices = (uuid: string | null = null) => {
  const voiceData = useRecord(uuid);
  const voicesData = useRecords();

  return {
    voice: voiceData.data,
    voices: voicesData.data,
    isError: voiceData.isError || voicesData.isError,
    isLoading: voiceData.isLoading || voicesData.isLoading,
  };
};
