import {useTranslation} from "react-i18next";
import {useCallback} from "react";

export const useLinkFormatter = () => {
  const {i18n: { language }} = useTranslation();

  return useCallback((path: string, query?: Record<string, string | number>, hash?: string) => (
    `${path}?${new URLSearchParams({ ...query, lang: language })}${hash ?? ''}`
  ), [language]);
};
