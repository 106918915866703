import {forwardRef} from "react";
import type {InputHTMLAttributes, ReactNode} from "react";
import {cn} from "../utils";

export const Checkbox = forwardRef<HTMLInputElement, Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & { label: ReactNode; readonly textClassName?: string }>(({
  label,
  className,
  required,
  ...rest
}, ref) => (
  <label className={cn('inline-flex items-start cursor-pointer relative', required && 'before:content-["*"] before:absolute before:-left-2.5 before:-top-[3px] before:text-red-500')}>
    <input ref={ref} required={required} type="checkbox" {...rest} className={cn('accent-pink size-[18px]', className)} />
    <span className={cn("font-myriad text-sm/[18px] ml-1", "textClassName" in rest ? rest.textClassName : "text-white")}>{label}</span>
  </label>
));
