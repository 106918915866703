import {createContext, FC, PropsWithChildren, useCallback, useMemo, useState} from "react";
import {UUID_LS_KEY} from "../constants/ls";

type Data = {
  uuid: string | null,
};
type Ctx = [Data, (data: Partial<Data>) => void];

const KEYS = {
  uuid: UUID_LS_KEY,
};
export const DataContext = createContext<Ctx>([
  {
    uuid: null,
  },
  () => {},
]);

export const DataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [s, d] = useState({
    uuid: localStorage.getItem(UUID_LS_KEY),
  });
  const setData = useCallback((data: Partial<Data>) => {
    Object.keys(data).forEach((_k) => {
      const k = _k as keyof Data;
      if (data[k] || typeof data[k] === 'number') localStorage.setItem(KEYS[k], `${data[k]}`);
      else localStorage.removeItem(KEYS[k]);
    });
    d(c => ({ ...c, ...data }));
  }, []);
  const v = useMemo<Ctx>(() => [s, setData], [s, setData]);

  return (
    <DataContext.Provider value={v}>
      {children}
    </DataContext.Provider>
  );
};
