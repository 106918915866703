import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as Logo} from "../assets/icons/leo-logo.svg";
import {ReactComponent as ResultMic} from "../assets/images/result-mic.svg";
import {ReactComponent as LoadingIcon} from "../assets/icons/loading.svg";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {cn} from "../utils";
import {Text} from "./text";
import {Button} from "./button";
import {useVoices} from "../hooks/useVoices";
import { Share } from "./share";

export const Result = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get('uuid') ?? null;
  const { voice, voices, ...restVoices } = useVoices(uuid);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSocialVisible, setSocialVisibility] = useState(false);
  const isError = (!restVoices.isLoading && !voice?.name) || restVoices.isError;
  // const path = location.pathname.replaceAll('/', '');
  // const shouldRedirect = !!voice && !!voices && (location.pathname.replaceAll('/', '') !== (isWinner(voice, voices) ? 'won' : 'lost'));
  // const redirectTo = !shouldRedirect ? null : `${(path === 'won' ? '/lost' : '/won')}?uuid=${uuid}`;

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://platform-api.sharethis.com/js/sharethis.js#property=668c6bb4fbb12b0019fdb75a&product=inline-share-buttons&source=platform";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (isError) {
      navigate('/');
    }
  }, [navigate, isError]);
  // useEffect(() => {
  //   if (redirectTo) {
  //     navigate(redirectTo)
  //   }
  // }, [redirectTo, navigate]);

  return isError ? null : (
    <div className="max-w-screen-xl w-screen px-5 mx-auto py-6 print:p-0">
      {restVoices.isLoading ? (
        <div className="flex items-center justify-center min-h-full">
          <LoadingIcon className="animate-spin"/>
        </div>
      ) : (
        <div>
          <div
            onClick={() => setSocialVisibility(false)}
            className={cn('bg-[#7d69ac]/80 px-5 fixed inset-0 z-50 flex items-center justify-center transition-opacity', isSocialVisible ? 'opacity-100' : 'opacity-0 pointer-events-none')}
          >
            <div className="bg-white rounded-lg p-5" onClick={e => e.stopPropagation()}>
              <div className="print-force-hidden sharethis-inline-share-buttons"/>
            </div>
          </div>
          <div className="print:hidden max-w-[600px] md:space-y-8 text-center mx-auto md:mb-7 mb-5">
            <Text as="div" shadow="dark" size="h2" uppercase className="max-md:mb-7">{t('THANKS FOR PARTICIPATING!')}</Text>
            <Text as="div" size="p" font="myriad" weight="normal" className="max-md:mb-3">{t('thanks_desc')}</Text>
            <Text as="div" size="h5" uppercase>{t('Your performance preview')}</Text>
          </div>
          <Share duration={Number(voice?.duration)} />
          <Button className="print:hidden uppercase mx-auto lg:mt-10 mt-5" onClick={() => setSocialVisibility(true)}>
            {t('Share your result and challenge your friends')}
          </Button>
        </div>
      )}
    </div>
  );
};
