import {cn} from "../../utils";
import styles from './styles.module.css';
import type {FC, PropsWithChildren} from "react";

export const Card: FC<PropsWithChildren<{ size?: 'm' | 'l'; color?: 'yellow' | 'default', className?: string }>> = ({ className, children, size = 'm', color = 'default' }) => {
  return (
    <div className={cn(styles.card, styles[`card_color_${color}`], styles[`card_size_${size}`], className)}>
      {children}
    </div>
  )
};
