import {Routes, Route, Link, useLocation, Navigate, useNavigate} from "react-router-dom";
import legoLogo from "../assets/icons/leo-logo.svg";
import {ReactComponent as NoteIcon} from "../assets/icons/note.svg";
import {ReactComponent as FBIcon} from "../assets/icons/facebook.svg";
import {ReactComponent as InstIcon} from "../assets/icons/instagram.svg";
import {ReactComponent as YTIcon} from "../assets/icons/youtube.svg";
import {ReactComponent as MondelezIcon} from "../assets/icons/mondelez-international.svg";
import {Main} from "./main";
import {Text} from '../components/text';
import {Button} from '../components/button';
import {cn, disableScroll, enableScroll} from "../utils";
import {NotesBg} from "../components/notes-bg";
import {setDefaultOptions} from 'date-fns';
import {useTranslation} from "react-i18next";
import { nl, fr } from 'date-fns/locale';
import {LANG_LS_KEY} from "../constants/ls";
import {useEffect, useMemo, useRef, useState, useCallback} from "react";
import {WonPage} from "./won";
import {ParticipationPage} from "./participation";
import {TermsPage} from "./terms";
import {CookiesPage} from "./cookies";
import {useLinkFormatter} from "../hooks/useLinkFormatter";
import {Helmet} from "react-helmet";
import {OTContext} from "../contexts/ot";
import SocialMediaNL from "../assets/images/social_media_nl.jpg";
import SocialMediaFR from "../assets/images/social_media_fr.jpg";
import { SharePage } from "./share";

const DISK_STYLE = `relative max-lg:before:hidden before:content-[''] before:absolute before:left-[-18px] before:top-1/2 before:-translate-y-1/2 before:size-1 before:bg-white/20 before:rounded-full`
const BURGER_ROTATE = ['rotate-45 translate-y-2', 'scale-x-0', '-rotate-45 -translate-y-2'];

export const App = () => {
	const { t, i18n } = useTranslation();
	const formatLink = useLinkFormatter();
	const navigate = useNavigate();
	const prevHash = useRef('');
	const [otPrep, setOTPrep] = useState(false);
	const [otReady, setOTReady] = useState(false);
	const setLang = (lang: string) => i18n.changeLanguage(lang).then(() => {
		setOTReady(false);
		window.OneTrust?.changeLanguage(lang);
		localStorage.setItem(LANG_LS_KEY, lang);
		setDefaultOptions({ locale: { nl, fr }[lang] });
	});
	const [scrolledMenu, setScrolledMenu] = useState(false);
	const [noLayoutMode, setNoLayoutMode] = useState(false);
	const toggleScroll = useCallback((on: boolean) => on ? disableScroll() : enableScroll(), []);
	const [isMenuVisible, setMenuVisibility] = useState(false);
	const location = useLocation();
	const isParticipation = useMemo(() => location.pathname.replace(/\//g, '') === 'participation', [location.pathname]);
	const linksElem = (
		<>
			<Link to={formatLink('/', {}, '#prizes')} onClick={() => setMenuVisibility(false)}><Text uppercase weight="semi">{t('Prizes')}</Text></Link>
			<Link to={formatLink('/', {}, '#leaderboard')} onClick={() => setMenuVisibility(false)}><Text uppercase weight="semi">{t('Leaderboard')}</Text></Link>
			<Link to={formatLink('/', {}, '#info')} onClick={() => setMenuVisibility(false)}><Text uppercase weight="semi">{t('Info')}</Text></Link>
		</>
	);

	useEffect(() => {
		if (location.hash === prevHash.current) return;
		const el = location.hash && document.querySelector(location.hash);

		prevHash.current = location.hash;
		if (el) {
			setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'start' }), 0);
		}
	}, [location.hash]);
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (!params.has('lang')) {
			params.append('lang', i18n.language);
			navigate({ search: params.toString() });
		}
	}, [navigate, location.search, i18n.language]);
	useEffect(() => {
		toggleScroll(isMenuVisible);
	}, [isMenuVisible, toggleScroll]);
	useEffect(() => {
		window.OptanonWrapper = function(...args) {setOTReady(true);};
		setOTPrep(true);
	}, []);
	useEffect(() => {
		const mm = window.matchMedia(`(max-width: 900px)`);
		const onMMChange = (e: MediaQueryListEvent) => {
			if (!e.matches) {
				setMenuVisibility(false);
			}
		};
		mm.addEventListener('change', onMMChange);
		return () => mm.removeEventListener('change', onMMChange);
	}, []);
	useEffect(() => {
		const onScroll = () => (document.documentElement.scrollTop > 50) !== scrolledMenu && setScrolledMenu(s => !s);
		document.addEventListener('scroll',  onScroll);
		onScroll();
		return () => document.removeEventListener('scroll',  onScroll);
	}, [scrolledMenu, setScrolledMenu]);

	return (
		<div className={cn(!noLayoutMode && "grid grid-rows-[1fr_auto] min-[900px]:pt-[146px] pt-[76px] min-h-screen relative print:block")}>
			{!noLayoutMode && <Helmet>
				<title>{t('title')}</title>
				<meta property="description" content={t('description')}/>
				<meta property="og:title" content={t('title')}/>
				<meta property="og:description" content={t('description')}/>
				<meta property="og:image" content={i18n.language === 'nl' ? SocialMediaNL : SocialMediaFR} />
			</Helmet>}
			{!noLayoutMode && otPrep && (
				<Helmet>
					<script
						src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
						data-language={i18n.language}
						type="text/javascript" data-domain-script="c37fcaf6-08b3-4639-bb56-9061b3028271"
					/>
				</Helmet>
			)}
			{!noLayoutMode && <NotesBg className="max-h-screen print:hidden"/>}
			{!noLayoutMode &&
				<header
					className={cn(
						'print:hidden fixed bg-transparent top-0 inset-x-0 z-20 grid grid-cols-2 min-[900px]:grid-cols-3 justify-between items-center px-5 min-[900px]:px-10 transition-all',
						scrolledMenu ? 'bg-[#7d69ac] py-3' : 'min-[900px]:py-7 py-5',
					)}
				>
					<Link to={formatLink('/')} className="justify-self-start">
						<img src={legoLogo} className={cn('w-[61px] min-[900px]:w-[163px] aspect-[163/90] transition-all', scrolledMenu && 'max-w-[100px]')} />
					</Link>
					<div className="space-x-8 justify-self-center hidden min-[900px]:block">
						{linksElem}
					</div>
					<div className="flex items-center gap-x-5 justify-self-end">
						{!isParticipation && (
							<Button to={formatLink('/participation')} className="hidden min-[1100px]:inline-flex">
								<NoteIcon />
								<span>{t('Participate')}</span>
							</Button>
						)}
						{['nl', 'fr'].map((lang) => (
							<button
								key={lang}
								onClick={() => setLang(lang)}
								className={cn('text-white min-[900px]:px-4 min-[900px]:py-3 py-2.5 px-2 border rounded-[10px] border-white uppercase font-myriad font-semibold text-sm/none min-[900px]:text-[17px]/none', lang !== i18n.language && 'opacity-50')}
							>
								{lang}
							</button>
						))}
						<button className="min-[900px]:hidden relative z-20 w-5 space-y-1.5" onClick={() => setMenuVisibility(v => !v)}>
							{[0, 1, 2].map((i) => (
								<div key={i} className={cn('w-full h-0.5 bg-[#ee3e88] rounded-full transition-transform', isMenuVisible && BURGER_ROTATE[i])} />
							))}
						</button>
					</div>
				</header>}
			{!noLayoutMode &&
				<div
					className={cn(
						`print:hidden min-[900px]:hidden fixed bg-[#7d69ac] inset-0 z-[15] flex flex-col items-center justify-center gap-5 overflow-auto transition-opacity`,
						isMenuVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
					)}
				>
					{linksElem}
				</div>}
			<OTContext.Provider value={otReady}>
				<Routes>
					<Route path="/" element={<Main/>}/>
					<Route path="/result" element={<WonPage/>}/>
					<Route path="/participation" element={<ParticipationPage/>}/>
					<Route path="/terms" element={<TermsPage/>}/>
					<Route path="/cookies" element={<CookiesPage/>}/>
					<Route path="/share" element={<SharePage setNoLayoutMode={setNoLayoutMode} />}/>
					<Route path="*" element={<Navigate to="/" />}/>
				</Routes>
			</OTContext.Provider>
			{!noLayoutMode &&
				<footer className="print:hidden lg:grid lg:grid-cols-2 lg:grid-rows-[1fr_auto] max-lg:flex max-lg:flex-col px-6 py-8 items-center gap-y-4">
					<MondelezIcon className="max-lg:mb-5" />
					<div className="flex gap-6 justify-self-end max-lg:mb-4">
						<YTIcon />
						<FBIcon />
						<InstIcon />
					</div>
					<div className="font-alwyn text-sm/none text-white lg:space-x-9 max-lg:gap-3 max-lg:flex max-lg:flex-col max-lg:items-center max-lg:mb-3">
						<Link to={formatLink('/terms')}>{t('Terms & Conditions')}</Link>
						<a href={`https://privacy.mondelezinternational.com/eu/${i18n.language}-${i18n.language.toUpperCase()}/privacy-notice/`} target="_blank" className={cn(DISK_STYLE)} rel="noreferrer">{t('Privacy Policy')}</a>
						<Link to={formatLink('/cookies')} className={cn(DISK_STYLE)}>{t('Cookie Policy')}</Link>
						<a href="https://contactus.mdlzapps.com/form?siteId=HyLfZ7YcmVFoh82Ii/kwLg==" target="_blank" className={cn(DISK_STYLE)} rel="noreferrer">{t('Contact')}</a>
					</div>
					<div className="font-alwyn text-sm/none justify-self-end text-white">
						© 2024 {t('Mondelēz International. All Rights Reserved')}
					</div>
				</footer>}
		</div>
	);
};
