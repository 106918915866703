import {BrowserRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import {App} from "./pages";
import * as Sentry from "@sentry/react";

import './i18n';
import './index.css';
import {DataProvider} from "./contexts/data";
import 'context-filter-polyfill';

export const client = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 2000,
		},
	},
});


if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: "https://7da709527d0548e2abdbffe855a02050@sentry.stg.onepunchagency.eu/35",
		integrations: [],
		sampleRate: 1.0,
		tracesSampleRate: 0.0,
	});
}

createRoot(document.getElementById('root')!).render(
	<BrowserRouter>
		<QueryClientProvider client={client}>
			<DataProvider>
				<App />
			</DataProvider>
			<Toaster toastOptions={{ className: 'bg-pink text-white max-w-[500px] font-myriad' }} />
			{process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} position="left" />}
		</QueryClientProvider>
	</BrowserRouter>
);
