import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';

import type {ClassValue} from 'clsx';
import {Voice} from "../@types";

const preventDefault = (e: Event) => e.preventDefault();

const preventDefaultForScrollKeys = (e: KeyboardEvent) => {
  if ({ ArrowLeft: true, ArrowUp: true, ArrowRight: true, ArrowDown: true, Space: true, PageUp: true, PageDown: true, End: true, Home: true }[e.code]) {
    preventDefault(e);
    return false;
  }
}

let supportsPassive = false;
try {
  window.addEventListener('test' as any, null as any, Object.defineProperty({}, 'passive', {
    get() {
      supportsPassive = true;
      return true;
    }
  }));
} catch(e) {}

export const isWinner = (voice: Voice, voices: Voice[]) => voices.some(item => item.uuid === voice.uuid);

const wheelOpt: any = supportsPassive ? { passive: false } : false;

export const disableScroll = () => {
  window.addEventListener('DOMMouseScroll', preventDefault, false);
  window.addEventListener('wheel', preventDefault, wheelOpt);
  window.addEventListener('touchmove', preventDefault, wheelOpt);
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

export const enableScroll = () => {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener('wheel', preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));
