import {useTranslation} from "react-i18next";
import {Button} from "../components/button";
import {useContext, useEffect} from "react";
import {OTContext} from "../contexts/ot";

export const CookiesPage = () => {
  const {i18n} = useTranslation();
  const otReady = useContext(OTContext);

  useEffect(() => {
    if (otReady) {
      window.OneTrust?.initializeCookiePolicyHtml();
    }
  }, [otReady]);

  return (
    <>
      <div
        className="max-w-[1400px] p-10 mx-auto text-white font-myriad space-y-5 text-lg [&_#ot-sdk-cookie-policy_*:not(a):not(th)]:text-white">
        {i18n.language === 'nl' ? (<>
          <h1 className="text-center text-4xl font-bold">COOKIEBELEID</h1>
          <p>
            Een cookie is een klein tekstbestand dat op uw computer of mobiele apparaat
            wordt opgeslagen wanneer u een website bezoekt. Cookies worden vervolgens
            teruggestuurd naar de oorspronkelijke website bij elk volgend bezoek, of
            naar een andere website die die cookie herkent, om een historiek van
            uwonline activiteit op te maken. Cookies op deze site kunnen::
          </p>
          <ul className="list-disc pl-10">
            <li>
              AUTOMATISCH VERWIJDERD WORDEN NA ELK BEZOEK (SESSIECOOKIES) OF OPGESLAGEN
              WORDEN BIJ MEERDERE BEZOEKEN (PERMANENTE COOKIES);
            </li>
            <li>
              AANGELEVERD WORDEN IN EERSTE PARTIJ (DOOR ONS INGESTELD) OF DERDE PARTIJ
              (INGESTELD DOOR EEN ANDERE WEBSITE) CONTEXT.
            </li>
          </ul>
          <p>
            Op deze website gebruiken wij en derde partijen cookies voor verschillende
            doeleinden, waaronder: het vergemakkelijken van uw navigatie, het
            personaliseren van inhoud, het afstemmen van advertenties op uw interesses
            en het meten van sitegebruik. In het bijzonder gebruiken we de volgende
            cookies:
          </p>
          <div id="ot-sdk-cookie-policy"></div>
          <h3 className="h3">Van websites van derde partijen</h3>
          <p>
            Wanneer u onze website gebruikt, kunt u ingebedde inhoud tegenkomen, of kunt
            u voor bepaalde activiteiten naar andere websites geleid worden. Deze
            websites en ingebedde inhoud kunnen hun eigen cookies gebruiken. We hebben
            geen controle over het plaatsen van cookies door andere websites, zelfsniet
            als u hiernaar wordt verwezen vanaf onze website.
          </p>
          <p>
            Onze Cookie-voorkeuren applicatie kan worden gebruikt om uw cookievoorkeuren
            aan te passen. De applicatie registreert uw toestemming op ons cookiebeleid
            en zal hier elke 12 maanden opnieuw om vragen om ervoor te zorgen dat u op
            de hoogte blijft van eventuele wijzigingen in ons cookiebeleid. De
            applicatie beheert de door ons ingestelde Performantie, Functionele en
            Gerichte cookies. Strikt Noodzakelijke cookies kunnen niet worden
            uitgeschakeld, noch kan de applicatie worden gebruikt om cookies te
            blokkeren op websites van derden die zijn gelinkt vanaf onze website.
          </p>
          <p>
            Veel van de cookies die op onze website worden gebruikt, kunnen ook via uw
            browser worden in- of uitgeschakeld. Volg hiervoor de instructies die
            zichmeestal in de menu's 'Help', 'Extra' of 'Bewerken' in uw browser
            bevinden. Houd er rekening mee dat als u een cookie of een categorie cookies
            uitschakelt, de cookie niet uit uw browser wordt verwijderd, tenzij dit
            handmatig wordt gedaan via uw browserfunctie.
          </p>
          <Button className="border-0" onClick={() => window.OneTrust?.ToggleInfoDisplay()}>
            Cookie-instellingen
          </Button>
        </>) : (
          <>
            <h1 className="text-center text-4xl font-bold">POLITIQUE RELATIVE AUX COOKIES</h1>
            <p>
              Un cookie est un petit fichier texte enregistré sur votre ordinateur ou
              votre appareil mobile lorsque vous visitez un site Web. Les cookies sont
              ensuite renvoyés au site Web d'origine lors de chaque visite ultérieure, ou
              à un autre site Web qui reconnaît ce cookie, afin de créer un enregistrement
              de votre activité en ligne. Les cookies sur ce site peuvent être:
            </p>
            <ul className="list-marker">
              <li>
                SUPPRIMÉS AUTOMATIQUEMENT APRÈS CHAQUE VISITE (COOKIES DE SESSION) OU
                MAINTENUS EN PLACE LORS DE VISITES MULTIPLES (COOKIES PERSISTANTS);
              </li>
              <li>
                LIVRÉS DANS UN CONTEXTE PROPRIÉTAIRE (DÉFINI PAR NOUS) OU DE TIERS (DÉFINI
                PAR UN AUTRE SITE WEB).
              </li>
            </ul>
            <p>
              Sur ce site Web, nous et des tiers utilisons des cookies à différentes fins,
              notamment: faciliter votre navigation, personnaliser le contenu de la page,
              adapter la publicité à vos centres d'intérêts et mesurer l'utilisation du
              site. Plus précisément, nous utilisons les cookies suivants:
            </p>
            <div id="ot-sdk-cookie-policy"></div>
            <h3 className="h3">Le cookies tiers</h3>
            <p>
              Lorsque vous utilisez notre site Web, vous pouvez rencontrer du contenu
              intégré ou être dirigé vers d'autres sites Web pour certaines activités. Ces
              sites Web et les contenus intégrés peuvent utiliser leurs propres cookies.
              Nous ne contrôlons pas le placement de cookies par d'autres sites Web, même
              si vous y êtesdirigé depuis notre site Web.
            </p>
            <p>
              Notre outil de préférences de cookies peut être utilisé pour personnaliser
              vos préférences de cookies. L'outil enregistrera votre consentement ànotre
              politique relative aux cookies et vous le redemandera tous les 12 mois afin
              devous tenir au courant des modifications apportées à notre politique
              relative aux cookies. Notre outil contrôle les cookies de performance, de
              fonctionnalité et pour une publicité ciblée que nous avons définis. Les
              cookies strictement nécessaires ne peuvent pas être désactivés, et l'outil
              ne peut être utilisé pour bloquer les cookies de sites tiers liés à notre
              site Web.
            </p>
            <p>
              De nombreux cookies utilisés sur notre site Web peuvent également être
              activés ou désactivés via votre navigateur. Pour ce faire, suivez les
              instructions qui figurent généralement dans les menus «Aide», «Outils» ou
              «Éditer» de votre navigateur. Veuillez noter que la désactivation d'un
              cookie ou d'une catégorie de cookies ne supprime pas le cookie de votre
              navigateur, sauf si elle est manuellement complétée via la fonction de votre
              navigateur.
            </p>
            <Button className="border-0" onClick={() => window.OneTrust?.ToggleInfoDisplay()}>
              Paramètres de cookies
            </Button>
          </>
        )}
      </div>
    </>
  );
};
