import {cn} from "../utils";
import type {FC} from "react";
import notesBG from "../assets/images/notes_bg.png";

export const NotesBg: FC<{ className?: string }> = ({ className }) => (
  <div className={cn('absolute -z-10 w-screen h-[768px] overflow-hidden left-1/2 top-0 -translate-x-1/2 mix-blend-screen', className)}>
    <div
      style={{'--image-url': `url(${notesBG})`} as any}
      className="absolute w-[1366px] left-1/2 inset-y-0 bg-[image:--image-url] -translate-x-1/2"
    />
  </div>
);
