// -*- tab-width: 2; indent-tabs-mode: t; -*-
import {config} from "../../config";
import {FC, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from 'react-hot-toast';
import {useMutation} from '@tanstack/react-query';
import {Voice} from "../../@types";
import {ReactComponent as NoteIcon} from "../../assets/icons/note.svg";
import {ReactComponent as CrownIcon} from "../../assets/icons/crown.svg";
import {ReactComponent as LoadingIcon} from "../../assets/icons/loading.svg";
import {ReactComponent as SoundEffectImage} from '../../assets/images/sound_effect.svg';
import {ReactComponent as MicrophoneImage} from '../../assets/images/microphone.svg';
import {useRecords} from "../../hooks/useRecords";
import {differenceInMilliseconds, differenceInSeconds} from "date-fns";
import {Text} from "../../components/text";
import {cn} from "../../utils";
import {Button} from "../../components/button";
import {Card} from "../../components/card";
import {TimePill} from "../../components/time-pill";
import {useLSData} from "../../hooks/useLSData";
import { useMicVAD, utils } from "../../vad";
import type { SpeechProbabilities } from "@ricky0123/vad-web/dist/_common/models";
import classNames from 'classnames';
import percentile from "percentile";
import bgVideoURL from './bg.mp4';

const RECORD_STYLES = [
	'',
	'md:pl-24 md:-translate-x-5',
	'md:pl-24 md:-translate-x-10',
];

export const ParticipationRecord: FC<{ duration?: number, onData: (data: { uuid: string | null, duration: number }) => void }> = ({ duration, onData }) => {
	const [, setLSData] = useLSData();
	const {t} = useTranslation();
	const videoElemRef = useRef<HTMLVideoElement | null>(null);
	const canvasElemRef = useRef<HTMLCanvasElement | null>(null);
	const { mutate, isPending } = useMutation({
		mutationFn: (body: FormData) => fetch(`${config.backend}/api/voices`, {
			method: 'POST',
			body,
			headers: {
				'ACCEPT': 'application/json'
			}
		}),
		onSuccess: async (r) => {
			const d = await r.json() as Voice | { error: string };

			if ('uuid' in d) {
				setLSData({ uuid: d.uuid });
				onData({ uuid: d.uuid, duration: durationRef.current });
			} else {
				if (videoElemRef.current) {
					videoElemRef.current.currentTime = 0;
				}
				toast.error(d.error);
			}
		},
		onError: () => {
			if (videoElemRef.current) {
				videoElemRef.current.currentTime = 0;
			}
			toast.error(t('Something went wrong'));
		},
	});
	const isMountedRef = useRef(false);
	const timerElRef = useRef<HTMLSpanElement | null>(null);
	const [isStarting, setStarting] = useState(false);
	const startRef = useRef<number>(0);
	const [recordingMode, setRecordingMode] = useState(false);
	const recordingModeRef = useRef<boolean>(false);
	const { data: itemsData } = useRecords();
	const stopRecording = () => {
		if (vadRef.current?.listening) {
			vadRef.current.pause();
		}

		videoElemRef.current?.pause();

		setStarting(false);
		setRecordingMode(false);
	};

	const probabilitiesRef = useRef<SpeechProbabilities>({ isSpeech: 0, notSpeech: 1});
	const [stream, setStream] = useState<MediaStream | null>(null);
	const [mediaAccessError, setMediaAccessError] = useState<Error | null>(null);
	const silenceProbabilitiesRef = useRef<{
		readonly probabilities: readonly SpeechProbabilities[],
		readonly minSpeech: number;
		readonly calculationFinished: boolean;
	}>({
		probabilities: [],
		minSpeech: 0,
		calculationFinished: false,
	});

	const vadOptions = {
		frameSamples: 1536,
		minSpeechFrames: 10,
		redemptionFrames: 100,
		positiveSpeechThreshold: 0.5,
		negativeSpeechThreshold: 0.35,
		submitUserSpeechOnPause: true,
	};

	const vad = useMicVAD({
		...vadOptions,
		preSpeechPadFrames: 60,
		userSpeakingThreshold: vadOptions.negativeSpeechThreshold,
		stream: stream || undefined,
		startOnLoad: true,
		onFrameProcessed: (probabilities) => {
			probabilitiesRef.current = probabilities;
			console.log(probabilities.isSpeech);

			if (!silenceProbabilitiesRef.current.calculationFinished
				&& (probabilities.isSpeech >= vadOptions.positiveSpeechThreshold
					|| startRef.current - Date.now() < 500)
			) {
				silenceProbabilitiesRef.current = {
					...silenceProbabilitiesRef.current,
					calculationFinished: true,
				};
				console.log(silenceProbabilitiesRef.current);
			}

			if (!silenceProbabilitiesRef.current.calculationFinished) {
				const silenceProbabilities = [...silenceProbabilitiesRef.current.probabilities, probabilities];
				silenceProbabilitiesRef.current = {
					probabilities: silenceProbabilities,
					minSpeech: (percentile(
						silenceProbabilities.length > 15 ? 20 : 5,
						silenceProbabilities.map(({ isSpeech }) => isSpeech)) as number
					) * 0.8,
					calculationFinished: false,
				};
			}

			if (startRef.current - Date.now() > 500) {
				return;
			}

			if (recordingMode && probabilities.isSpeech < silenceProbabilitiesRef.current.minSpeech) {
				console.log({
					minSpeech: silenceProbabilitiesRef.current.minSpeech,
					isSpeeech: probabilities.isSpeech,
				});
				stopRecording();
			}
		},
		onSpeechStart: () => {
			console.log("User started talking");
			if (startRef.current - Date.now() > 500) {
				console.log(startRef.current - Date.now());
				return;
			}
			if (!recordingMode) {
				setStarting(false);
				setRecordingMode(true);
			}
		},
		onSpeechEnd: (audio) => {
			console.log("User stopped talking");
			if (startRef.current - Date.now() > 300) {
				return;
			}

			stopRecording();

			const formData = new FormData();
			formData.set('file', new File([utils.encodeWAV(audio)], "test", { type: 'wav' }));
			mutate(formData);
		},
		onVADMisfire: () => {
			console.log('misfire');
			if (startRef.current - Date.now() > 300) {
				return;
			}

			toast.error(t('Too short!'));
			stopRecording();
			startRef.current = 0;
			if (videoElemRef.current) {
				videoElemRef.current.currentTime = 0;
			}
			onData({ duration: durationRef.current, uuid: null });
		},
	});

	const vadRef = useRef<null | typeof vad>(null);
	const durationRef = useRef<number>(duration || 0);

	const startRecording = async () => {
		if (!stream) {
			try {
				setStream(await navigator.mediaDevices.getUserMedia({
					audio: {
						channelCount: 1,
						echoCancellation: false,
						autoGainControl: false,
						noiseSuppression: false,
						sampleSize: 16,
						sampleRate: 16000,
					},
				}));
			} catch (e) {
				if (e instanceof Error) {
					setMediaAccessError(e);
				}
				return;
			}
		}

		startRef.current = Date.now() + 3000;
		let vadActivated = false;
		let videoStarted = false;

		const update = () => {
			if (!isMountedRef.current) return;

			if (vadRef.current?.loading) {
				startRef.current = Date.now() + 3000;
				requestAnimationFrame(update);
				return;
			}

			if (videoElemRef.current && videoElemRef.current.paused && !videoStarted) {
				videoElemRef.current.play();
				videoStarted = true;
			}

			if (startRef.current > Date.now()) {
				if (startRef.current - Date.now() < 500 && !vadActivated) {
					vadActivated = true;
					vadRef.current?.pause();
					vadRef.current?.start();
				}

				requestAnimationFrame(update);
				return;
			}

			if (timerElRef.current) {
				const now = Date.now();
				durationRef.current = (now - startRef.current) / 1000;
				timerElRef.current.innerHTML = `${differenceInSeconds(now, startRef.current)}.${`${Math.round(differenceInMilliseconds(now, startRef.current) % 1000 / 10)}`.padEnd(2, '0')} SEC`;
			}

			if (vadRef.current?.listening || startRef.current + 3000 > Date.now()) {
				requestAnimationFrame(update);
			}
		};

		setStarting(true);
		requestAnimationFrame(update);
	};

	useEffect(() => {
		fetch('/static/js/vad.worklet.bundle.min.js'); // preload vad worklet.
		fetch('/static/js/silero_vad.onnx'); // preload vad model.
		fetch('/static/js/ort-wasm-simd.wasm'); // preload onnx runtime wasm.
	}, []);

	useEffect(() => {
		if (recordingMode) {
			startRef.current = Date.now();
		}
	}, [recordingMode]);

	useEffect(() => {
		vadRef.current = vad;
		if (vad.errored) {
			toast.error(vad.errored.message);
		}
		console.log({ vad: {...vad} });
	}, [vad, vadRef]);

	useEffect(() => {
		recordingModeRef.current = recordingMode;
	}, [recordingMode, recordingModeRef]);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			void stopRecording();
			isMountedRef.current = false;
		};
	}, []);

	const onPlay = useCallback(() => {
		const process = () => {
			if (!canvasElemRef.current || !videoElemRef.current) {
				return;
			}

			if (videoElemRef.current.paused || videoElemRef.current.ended) {
				return;
			}

			const ctx = canvasElemRef.current.getContext('2d');

			if (!ctx) {
				return;
			}

			ctx.filter = `blur(${recordingModeRef.current && !vadRef.current?.userSpeaking ? 2 : 0}px)`;
			ctx.drawImage(videoElemRef.current, 0, 0, canvasElemRef.current.width, canvasElemRef.current.height);

			requestAnimationFrame(process);
		};

		process();
	}, [canvasElemRef.current, videoElemRef.current]);

	return (
		<div className="max-w-screen-xl w-screen px-5 mx-auto py-6 print:p-0">
			<div className="flex flex-col items-center">
				<div className="max-w-[600px] space-y-5 md:space-y-8 mb-10">
					<Text as="div" shadow="dark" uppercase center size="h3">
						{t('TAKE ON THE CHALLENGE, SING AS LONG AS YOU CAN!')}
					</Text>
					<Text as="div" shadow="dark" color="pink" uppercase center size="h2">
						{recordingMode && t('Sing "LEOOOO…"')}
						{!isPending && !recordingMode && t('Ready, set, go!')}
						{isPending && t('BRAVOOO!')}
					</Text>
					{/*<Text as="div" shadow="lite" uppercase center size="h4">
						<span className={cn(!recordingMode && 'hidden')} ref={timerElRef}>00.00 SEC</span>
						<span className={cn(recordingMode && 'hidden')}>{t('Your current time: {{sec}} sec', { sec: (durationRef.current || 0).toFixed(2).padStart(5, '0') })}</span>
						</Text> */}
				</div>
				<div className="relative w-full max-w-[500px] md:flex md:items-center md:justify-center">
					<div className="relative w-full aspect-square flex items-center justify-center md:z-10 max-md:mb-14">
						<SoundEffectImage
							className={cn('absolute size-[120%] inset-[-10%]', !recordingMode && 'opacity-10', recordingMode && 'animate-pulse')}
						/>
						{<MicrophoneImage
							className={cn('[&>g]:transition-all absolute inset-[5%] size-[90%]', !recordingMode && '[&>g]:blur-md')}
						/>}
						<video
							ref={videoElemRef}
							muted
							onPlay={onPlay}
							autoFocus={false}
							autoPlay={false}
							playsInline={true}
							className="hidden"
						>
							<source src={bgVideoURL} type="video/mp4" />
						</video>

						<canvas
							ref={canvasElemRef}
							height="500"
							width="500"
							className={cn(
								'[&>g]:transition-all absolute inset-[5%] size-[90%] rounded-full pointer-events-none',
								!recordingMode && '[&>g]:blur-md',
								startRef.current <= 0 && 'hidden'
							)}
						>
						</canvas>

						{!isPending && !isStarting && !vad.errored && (!vad.loading || !stream) && !mediaAccessError && !recordingMode && (
							<Button
								className="relative"
								color="yellow"
								onClick={() => recordingMode ? stopRecording() : startRecording()}>
								<NoteIcon className={classNames({"animate-spin": vad.userSpeaking}, "relative")}/>
								<span>{t(recordingMode ? 'STOP RECORDING' : 'START RECORDING')}</span>
							</Button>
						)}
						{(isPending || vad.errored || (vad.loading && stream) || mediaAccessError) &&  (
							<div className="relative flex flex-col items-center justify-center">
								{(((vad.loading || videoElemRef.current?.readyState !== 4) && stream) || isPending) && <LoadingIcon className="animate-spin"/>}
								<Text as="div" size="p" center font="myriad" weight="normal" className="mt-2 max-w-[60%]">
									{isPending && t('Please wait while our system is processing your performance.')}
									{vad.errored && vad.errored.message}
									{mediaAccessError && t('mic-denied')}
									{!mediaAccessError && (vad.loading || videoElemRef.current?.readyState !== 4) && t('Please wait, preparing your stage…')}
								</Text>
							</div>
						)}
					</div>
					{!recordingMode && (itemsData?.length || 0) >= 3 && (
						<div className="md:absolute md:min-w-[400px] md:max-w-[500px] md:right-0 md:translate-x-[85%]">
							<Text as="div" size="h4" className="mb-6 uppercase md:pl-12 max-md:hidden">{t('current top 3')}</Text>
							{itemsData?.slice(0, 3).map(({name, duration}, i) => (
								<Card
									key={i}
									size="l"
									color={!i ? 'yellow' : 'default'}
									className={cn('flex relative justify-between items-center md:pr-6 md:pl-16 md:py-4 p-3 mb-2.5 gap-3', RECORD_STYLES[i])}
								>
									{!i && <CrownIcon className="absolute -top-4 -right-[18px] rotate-[30deg]"/>}
									<Text as="div" shadow="lite" size="h5" uppercase className="flex-1 truncate">{i + 1}. {name}</Text>
									<TimePill sec={duration ?? 0} className="flex-none"/>
								</Card>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
