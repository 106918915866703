import { useQuery } from "@tanstack/react-query";
import { RECORD_QUERY_OPTIONS } from "../constants/query";
import { EMPTY_RECORD_UUID } from "../constants";

export const useRecord = (uuid: string | null) =>
  useQuery({
    ...RECORD_QUERY_OPTIONS(uuid),
    refetchInterval: (d) => {
      return uuid &&
        uuid !== EMPTY_RECORD_UUID &&
        d.state.data?.status !== "done" &&
        !d.state.data?.name &&
        d.state.data
        ? 1000
        : false;
    },
    retry: 10,
  });
