import {cn} from "../utils";
import {Link} from 'react-router-dom';
import type {ButtonHTMLAttributes, AnchorHTMLAttributes, FC} from "react";

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement> & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & { color?: 'yellow' | 'default', to?: string }> = ({ className, color = 'default', to, ...rest }) => {
  const Component = typeof to === 'string' ? Link : 'button';
  const props = {
    className: cn(
      !color || color === 'default' ? 'text-white bg-pink shadow-c-pink' : 'text-dark-purple bg-yellow shadow-c-yellow',
      'flex justify-center items-center gap-x-[9px] no-underline font-bold uppercase px-5 py-3 tracking-wider rounded-[10px] text-[17px]/[20px]',
      '[&:not(:disabled)]:active:shadow-none [&:not(:disabled)]:active:translate-y-0.5 [&:not(:disabled)]:hover:opacity-90 disabled:opacity-80 [&:not(:disabled)]:cursor-pointer',
      className,
    ),
    to,
    ...rest,
  } as any;
  return <Component {...props}/>;
};
