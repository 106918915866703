const devConfig = {
  backend: "",
};

const prodConfig = {
  ...devConfig,
};

export const config =
  process.env.NODE_ENV === "development" ? devConfig : prodConfig;
