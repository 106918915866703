import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {setDefaultOptions} from 'date-fns';
import {nl as dNL, fr as dFR} from 'date-fns/locale';
import fr from './fr/translation.json';
import nl from './nl/translation.json';
import {LANG_LS_KEY} from "../constants/ls";

const getLang = (s?: string | null) => !s || !['fr', 'nl'].includes(s) ? null : s;

const lng = getLang(new URLSearchParams(window.location.search).get('lang')) ?? localStorage.getItem(LANG_LS_KEY) ?? 'nl';

setDefaultOptions({ locale: { nl: dNL, fr: dFR }[lng] });

i18next.use(initReactI18next).init({
  lng,
  debug: process.env.NODE_ENV === 'development',
  resources: {
    nl: { translation: nl },
    fr: { translation: fr },
  },
});
