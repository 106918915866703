import {cn} from "../utils";
import type {FC} from "react";

type Color = 'yellow' | 'default' | 'purple' | 'pink';

export const TimePill: FC<{ sec: number, className?: string, color?: Color, desktopColor?: Color }> = ({ sec, className, desktopColor, color }) => (
  <div
    className={cn(
      'inline-flex gap-2.5 justify-center items-center md:py-2 py-1 md:px-[14px] px-2.5 rounded-[30px] font-black text-sm leading-none tracking-wider text-white font-intro-black border border-white/30',
      color === 'yellow' && 'bg-[#fa8b42]',
      color === 'purple' && 'bg-[#7d69ac]',
      (!color || color === 'default' || color === 'pink') && 'bg-pink',
      desktopColor === 'yellow' && 'md:bg-[#fa8b42]',
      desktopColor === 'purple' && 'md:bg-[#7d69ac]',
      (desktopColor === 'pink' || desktopColor === 'default') && 'md:bg-pink',
      className,
    )}
  >
    {sec.toFixed(2)} sec
  </div>
);
