import { useEffect } from "react";
import { Share } from "../components/share";
import { useLocation } from "react-router-dom";

export type SharePageProps = {
	readonly setNoLayoutMode: (value: boolean) => void;
};

export const SharePage = (props: SharePageProps) => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	console.log(params);
	useEffect(() => {
		props.setNoLayoutMode(true);
	}, [props.setNoLayoutMode]);

	return (
		<>
			<Share duration={Number(params.get("duration"))} />
		</>
	);
};
