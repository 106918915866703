import { queryOptions } from "@tanstack/react-query";
import { EMPTY_RECORD_UUID } from "./index";
import { config } from "../config";
import { Voice } from "../@types";

export const RECORD_QUERY_OPTIONS = (uuid: string | null) =>
  queryOptions<Voice | null>({
    queryFn: () =>
      !uuid || uuid === EMPTY_RECORD_UUID
        ? Promise.resolve(null)
        : fetch(`${config.backend}/api/voices/${uuid}`)
            .then(async (r) => {
              if (!r.ok) {
                return null;
              }

              return await r.json();
            })
            .then((d) => (d ? { ...d, duration: +d.duration || 0 } : null)),
    queryKey: ["voice", uuid],
    staleTime: Infinity,
  });

export const RECORD_ITEMS_QUERY_OPTIONS = queryOptions<Voice[]>({
  queryFn: async () =>
    fetch(`${config.backend}/api/voices`)
      .then((r) => r.json())
      .then(({ data }: { data: Voice[] }) =>
        data.map((d) => ({ ...d, duration: +(d.duration || 0) || 0 })),
      ),
  queryKey: ["voices"],
  staleTime: Infinity,
});
